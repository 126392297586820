<template>
  <div>
    <a-form-item
      :wrapper-col="{ span: 24 }"
    >
      <a-textarea
        :placeholder="placeholder"
        :rows="rows"
        :style="{ color: this.validateStatus === 'error' ? 'red' : '' }"
        v-model.trim="inputValue"
        @blur="handleIccids"
      />
    </a-form-item>

    <a-form-item label="卡号总数" :style="{ display: 'inline-block', width: '50%' }">
      <span class="cards-count">{{ cardsCount }}</span>
    </a-form-item>

    <a-form-item label="重复数量" :style="{ display: 'inline-block', width: '50%' }">
      <span class="cards-count">{{ duplicateCardsCount }}</span>
    </a-form-item>
  </div>
</template>

<script>
import { analyzeIccids, maxIccidLines } from '@/utils/iccid'

export default {
  name: 'CommonIccidInput',
  props: {
    rows: {
      type: Number,
      default: 10
    },
    /**
     * value 结构:
     * {
          inputValue: '', // 输入框内容
          data: []        // iccid数组
        }
     */
    // 如果传inputValue，则自动填充到输入框
    // 如果inputValue为空，data中有值，将data中的值填充到输入框
    // https://github.com/vueComponent/ant-design-vue/issues/915
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: Object
    }
  },
  data() {
    const value = this.value || {}

    // 如果inputValue为空，data中有值，将data中的值填充到输入框
    if (!value.inputValue && Array.isArray(value.data) && value.data.length > 0) {
      value.inputValue = value.data.join('\n')
    }

    return {
      placeholder: '可以直接复制excel中整列ICCID；\n' +
        '手动输入多个ICCID，一行一个；\n' +
        '连号的ICCID只需要输入最后几位不一样的即可，用波浪线连接。\n' +
        '例如: 89860451092270150000~100',
      cardsCount: 0,
      duplicateCardsCount: 0,
      inputValue: value.inputValue || '', // 输入框内容
      data: value.data || [], // iccid数组
      validateStatus: 'success',
      errorMsg: ''
    }
  },
  watch: {
    value(val = {}) {
      this.inputValue = val.inputValue
      this.data = val.data
    }
  },
  created() {
    if (this.inputValue) {
      this.handleIccids()
    }
  },
  methods: {
    triggerChange() {
      // Should provide an event to pass value to Form.
      this.$emit('change', Object.assign({}, this.$data))
    },

    handleIccids() {
      this.validateIccids()
      this.triggerChange()
    },

    validateIccids() {
      // 未输入内容
      if (!this.inputValue) {
        this.validateStatus = 'error'
        this.errorMsg = '请输入ICCID'
        return
      }

      // 处理行
      const { lines, validIccids, errorText } = analyzeIccids(this.inputValue)

      // 超过10万行不处理
      if (lines > maxIccidLines) {
        this.validateStatus = 'error'
        this.errorMsg = `卡数量不能超过${maxIccidLines}`
        return
      }

      // 处理错误
      if (errorText) {
        this.validateStatus = 'error'
        this.errorMsg = 'ICCID包含错误，无法提交'
      } else {
        this.validateStatus = 'success'
        this.errorMsg = null
      }

      // 拼接 textarea 内容(错误的放到最上面)
      this.inputValue = errorText + validIccids.join('\n')
      this.cardsCount = validIccids.length
      // 设置有效的iccid
      this.data = this.$lodash.uniq(validIccids)
      this.duplicateCardsCount = validIccids.length - this.data.length
    }
  }
}
</script>

<style lang="less" scoped>
.cards-count {
  padding-left: 20px;
}
</style>
