import lodash from 'lodash'
import luhn from 'luhn-js'

// 联通 iccid 前缀
const unicomIccidPrefix = ['898601', '898606', '898609']
// iccid 正则
const iccidPatten = /^([A-Za-z0-9]{19})([A-Za-z0-9])?$/
// 可处理的最大的iccid行数
export const maxIccidLines = 100000

// 分析输入的iccid
// @params
// inputText    textarea 输入的内容
// @return
// lines        inputText 总行数，超过maxIccidLines行不进行处理
// validIccids  有效的iccid数组
// errorText    无效的行(包含添加的错误信息)
export function analyzeIccids(inputText) {
  const iccids = lodash.split(inputText, /\r*\n/)
  var totalLines = iccids.length

  // 超过maxIccidLines行不进行校验
  if (totalLines > maxIccidLines) {
    return { lines: totalLines, validIccids: [], errorText: '' }
  }

  var validIccids = []
  var errorText = ''

  lodash(iccids).forEach(function(value) {
    value = lodash.trim(value)
    if (!value) {
      return
    }

    // 范围输入
    if (value.indexOf('~') > 0) {
      // 获取范围输入 iccid 数组
      const { lines, rangeIccids } = parseRangeIccids(value)
      // 超过maxIccidLines行不处理
      totalLines = totalLines - 1 + lines
      if (totalLines > maxIccidLines) {
        return
      }

      // 遍历范围输入iccid数组校验iccid
      lodash(rangeIccids).forEach(function(rangeIccid) {
        const { isValid, iccid } = validateIccid(rangeIccid)
        if (isValid) {
          validIccids.push(iccid)
        } else {
          errorText += iccid
        }
      })

      return
    }

    // 普通一个iccid
    const { isValid, iccid } = validateIccid(value)
    if (isValid) {
      validIccids.push(iccid)
    } else {
      errorText += iccid
    }
  })

  // 超过maxIccidLines行不进行校验
  if (totalLines > maxIccidLines) {
    return { lines: totalLines, validIccids: [], errorText: '' }
  }

  return { lines: totalLines, validIccids: validIccids, errorText: errorText }
}

// 处理范围iccid 89860451092270150000~100
// @return
// lines  解析出的iccid总行数，超过maxIccidLines行不进行处理
// rangeIccids 有效的iccid数组
function parseRangeIccids(inputText) {
  // 格式不对
  if (!/^[A-Za-z0-9]+[~][0-9]+$/.test(inputText)) {
    return { lines: 1, rangeIccids: [inputText] }
  }

  // fromIccid 起始iccid 89860451092270150000
  // toVal 范围值 '100'
  const [fromIccid, toVal] = inputText.split('~')
  // fromNumber 范围值开始 020
  var fromNumber = parseInt(fromIccid.slice(-toVal.length))
  // 格式不对
  if (isNaN(fromNumber)) {
    return { lines: 1, rangeIccids: [inputText] }
  }
  // toNumber 范围值结束 100
  const toNumber = parseInt(toVal)

  // 如果范围值写的不对直接返回本身
  if (fromNumber >= toNumber) {
    return { lines: 1, rangeIccids: [inputText] }
  }

  // 超过maxIccidLines张不处理
  if (toNumber - fromNumber + 1 > maxIccidLines) {
    return { lines: toNumber - fromNumber + 1, rangeIccids: [inputText] }
  }

  var iccids = []
  while (fromNumber <= toNumber) {
    iccids.push(`${fromIccid.slice(0, -String(fromNumber).length)}${fromNumber}`)
    fromNumber++
  }

  return { lines: iccids.length, rangeIccids: iccids }
}

// 校验输入的iccid格式是否正确
function validateIccid(inputIccid) {
  var isValid
  var iccid

  if (iccidPatten.test(inputIccid)) {
    isValid = true
    iccid = luhnCalculateIccid(inputIccid)
  } else {
    isValid = false
    if (inputIccid.indexOf(':') > 0) {
      iccid = `${inputIccid}\n`
    } else {
      iccid = `${inputIccid}:格式不正确\n`
    }
  }

  return { isValid: isValid, iccid: iccid }
}

function luhnCalculateIccid(iccid) {
  if (iccid.length === 19 && isChinaUnicomIccid(iccid)) {
    return luhn.generate(iccid)
  }

  return iccid
}

function isChinaUnicomIccid(iccid) {
  return unicomIccidPrefix.includes(iccid.substr(0, 6))
}
